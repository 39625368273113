import React, { useState, useEffect } from "react";
import YouTubeIcon from '@mui/icons-material/YouTube';

const FlashNews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showLink, setShowLink] = useState(false); // State to control link visibility

  const newsList = [
    "Going LIVE ICST 2025",
  ];

  useEffect(() => {
    const newsInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % newsList.length);
    }, 2000);

    const linkTimeout = setTimeout(() => {
      setShowLink(true); // Show the link after the news cycle starts
    }, 1000); // Adjust delay if needed

    return () => {
      clearInterval(newsInterval);
      clearTimeout(linkTimeout);
    };
  }, [newsList.length]);

  return (
    <div className="text-green-800 flex flex-col items-center mt-3">
      <div className="flex items-center justify-center space-x-2 animate-pulse duration-150">
        <a
          href="https://youtube.com/live/l_Mg_G13TJI?feature=share"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center space-x-2 hover:text-red-600 transition duration-300"
        >
          {/* YouTube icon with custom size */}
          <strong className="font-bold text-lg md:text-4xl w-auto text-blue-500 hover:text-blue-700 transition duration-300">
            <YouTubeIcon style={{ fontSize: '2.5rem' }} className="text-red-500 hover:text-red-600 mr-2" />
            Latest update:
          </strong>

          {/* News text with hover effect */}
          <span className="text-sm pb-1 md:text-3xl font-semibold text-gray-800 hover:text-gray-900 transition duration-300">
            {newsList[currentIndex]}
          </span>
        </a>
      </div>



      {showLink && ( // Conditionally render the link
        <div className="mt-2 animate-pulse duration-150"> {/* Added animation to the link as well */}
          <span className="text-sm md:text-xl">
            For Conference registration,{" "}
            <a
              href="https://forms.gle/tWPVg4RNquTXx5EXA"
              target="_blank"
              rel="noopener noreferrer"
              className="text-green-600 underline hover:text-green-900"
            >
              click here
            </a>
          </span>
        </div>
        
        
        
      )}
      {showLink && ( // Conditionally render the link
        <div className="mt-2 animate-pulse duration-150"> {/* Added animation to the link as well */}
          <span className="text-sm md:text-xl">
            For Conclave registration,{" "}
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfgfD4s1206cag5nlLotddp59iX4IcpkBfhLG0EPdtvaWXkeg/viewform?usp=dialog"
              target="_blank"
              rel="noopener noreferrer"
              className="text-green-600 underline hover:text-green-900"
            >
              click here
            </a>
          </span>
        </div>
      )}
      
    </div>
  );
};

export default FlashNews;